export interface AudioTrack {
  enabled: boolean;
  readonly id: string;
  kind: string;
  readonly label: string;
  language: string;
  readonly sourceBuffer: SourceBuffer | null;
}

declare var AudioTrack: {
  prototype: AudioTrack;
  new (): AudioTrack;
};

export interface AudioTrackListEventMap {
  addtrack: TrackEvent;
  change: Event;
  removetrack: TrackEvent;
}

/** Used to represent a list of the audio tracks contained within a given HTML media element, with each track represented by a separate AudioTrack object in the list. */
export interface AudioTrackList extends EventTarget {
  readonly length: number;
  onaddtrack: ((this: AudioTrackList, ev: TrackEvent) => any) | null;
  onchange: ((this: AudioTrackList, ev: Event) => any) | null;
  onremovetrack: ((this: AudioTrackList, ev: TrackEvent) => any) | null;
  getTrackById(id: string): AudioTrack | null;
  item(index: number): AudioTrack;
  addEventListener<K extends keyof AudioTrackListEventMap>(
    type: K,
    listener: (this: AudioTrackList, ev: AudioTrackListEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
  ): void;
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
  ): void;
  removeEventListener<K extends keyof AudioTrackListEventMap>(
    type: K,
    listener: (this: AudioTrackList, ev: AudioTrackListEventMap[K]) => any,
    options?: boolean | EventListenerOptions
  ): void;
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions
  ): void;
  [index: number]: AudioTrack;
}

declare var AudioTrackList: {
  prototype: AudioTrackList;
  new (): AudioTrackList;
};

/** A single video track from a <video> element. */
export interface VideoTrack {
  readonly id: string;
  kind: string;
  readonly label: string;
  language: string;
  selected: boolean;
  readonly sourceBuffer: SourceBuffer | null;
}

declare var VideoTrack: {
  prototype: VideoTrack;
  new (): VideoTrack;
};

export interface VideoTrackListEventMap {
  addtrack: TrackEvent;
  change: Event;
  removetrack: TrackEvent;
}

/** Used to represent a list of the video tracks contained within a <video> element, with each track represented by a separate VideoTrack object in the list. */
export interface VideoTrackList extends EventTarget {
  readonly length: number;
  onaddtrack: ((this: VideoTrackList, ev: TrackEvent) => any) | null;
  onchange: ((this: VideoTrackList, ev: Event) => any) | null;
  onremovetrack: ((this: VideoTrackList, ev: TrackEvent) => any) | null;
  readonly selectedIndex: number;
  getTrackById(id: string): VideoTrack | null;
  item(index: number): VideoTrack;
  addEventListener<K extends keyof VideoTrackListEventMap>(
    type: K,
    listener: (this: VideoTrackList, ev: VideoTrackListEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
  ): void;
  addEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
  ): void;
  removeEventListener<K extends keyof VideoTrackListEventMap>(
    type: K,
    listener: (this: VideoTrackList, ev: VideoTrackListEventMap[K]) => any,
    options?: boolean | EventListenerOptions
  ): void;
  removeEventListener(
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions
  ): void;
  [index: number]: VideoTrack;
}

declare var VideoTrackList: {
  prototype: VideoTrackList;
  new (): VideoTrackList;
};
